import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
}));

const Menu = ({ menu, height = { height: '390px' }, onClick = () => { } }) => {
  const classes = useStyles();

  return (
    <Card onClick={onClick} style={height}>
      <CardHeader
        title={menu.name}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        subheader={menu.recommended ? 'オススメ!' : null}
        action={menu.recommended ? <StarIcon /> : null}
        className={classes.cardHeader}
      />
      <CardContent>
        <div className={classes.cardPricing}>
          <Typography component="h3" variant="h4" color="textPrimary">
            {menu.price.toLocaleString()}円
          </Typography>
        </div>
        <ul>
          <Typography component="li" variant="subtitle1" align="center">
            施術時間の目安 : {menu.time}分
          </Typography>
        </ul>
        {menu.recommended}
        <Box mb="0.5rem" />
        <ul>
          <Typography component="li" variant="subtitle1">
            {menu.description.description}
          </Typography>
        </ul>
      </CardContent>
    </Card>
  );
}

export default Menu;

Menu.propTypes = {
  menu: PropTypes.object,
  height: PropTypes.object,
  onClick: PropTypes.func,
};
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuCard from '../components/molecules/menu'
import Payment from '../components/molecules/payment'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '1.5rem'
  },
  menuBox: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  menu: {
    marginBottom: '1rem'
  },
}));

const query = graphql`
  query {
    allContentfulMenu(sort: {fields: [order], order: ASC}) {
      edges {
        node {
          contentfulid
          name
          price
          time
          recommended
          description {
            description
          }
        }
      }
    }
  }
`

const Menu = () => {
  const classes = useStyles();
  const contentful = useStaticQuery(query)

  return <>
    <Typography className={classes.title} variant="h6" gutterBottom>
      メニュー・料金
    </Typography>
    <Typography className={classes.menuBox} align="center">
      <Grid container spacing={5} alignItems="flex-end">
        {contentful.allContentfulMenu.edges.map(menu => menu.node).map((menu) => (
          // Enterprise card is full width at sm breakpoint
          <Grid item key={menu.contentfulid} xs={12} sm={6} md={4}>
            <MenuCard menu={menu} />
          </Grid>
        ))}
      </Grid>
    </Typography>
    <Grid className={classes.menu} container spacing={3}>
      <Grid item xs={12} md={12}>
        <Payment />
      </Grid>
    </Grid>
  </>;
}

export default Menu;